// general file for helpers
// do not import files that have to be generated here (like chains.ts, typings.d.ts etc.)

// Extract view box from a svg string
export const extractViewBox = (svgString: string): string => {
  // Regular expression to match the viewBox attribute
  const viewBoxRegex = /viewBox\s*=\s*"([^"]+)"/
  const match = svgString.match(viewBoxRegex)

  if (match && match.length === 2) {
    return match[1]
  }

  return ""
}

// make sure a url doesn't have a trailing / that might generate broken link
export const removeTrailingSlashes = (
  inputString: string | undefined,
): string | undefined => {
  if (inputString) return inputString.replace(/\/+$/, "")
  else return inputString
}

export const isValidPath = (path: string) => {
  const pathPattern =
    /^\/[a-zA-Z0-9\-_]+(?:\/[a-zA-Z0-9\-_]+)*(?:\?[a-zA-Z0-9=&:%_]+)?$/

  return pathPattern.test(path)
}
