import type { GetServerSidePropsContext } from "next"
import nookies, { parseCookies } from "nookies"

import type { FeatureFlags } from "common/types/featureflags"
import { jsonParse } from "common/helpers/serialization"

enum Cookie {
  Token = "token",
  Features = "features",
  NewLayout = "newlayout",
}

const getCookie = (
  context: GetServerSidePropsContext,
  key: Cookie,
): undefined | string => {
  const cookies = nookies.get(context)
  const cookie = cookies?.[key]

  if (!cookie) return undefined

  return cookie
}

// eslint-disable-next-line import/no-unused-modules
export const getFeatures = (
  context?: GetServerSidePropsContext,
): FeatureFlags | undefined => {
  if (context) {
    const features = getCookie(context, Cookie.Features)

    if (!features) return undefined

    return jsonParse(features)
  } else {
    const cookies = parseCookies()

    if (!cookies?.features) return undefined

    return jsonParse(cookies.features)
  }
}

export const getSessionToken = (
  context?: GetServerSidePropsContext,
): string | undefined => {
  if (context) {
    return context ? getCookie(context, Cookie.Token) : undefined
  } else {
    const cookies = parseCookies()

    return cookies?.token ?? undefined
  }
}
